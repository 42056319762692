@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
::-webkit-scrollbar {
  width: 5px;
  height: 5px;
}

::-webkit-scrollbar-track {
  background-color: white;
}

::-webkit-scrollbar-thumb {
  background-color: #888;
  border-radius: 5px;
}

::-webkit-scrollbar-thumb:hover {
  background-color: #555;
}

::-webkit-scrollbar-corner {
  background-color: #f1f1f1;
}

.render-container {
  ol,
  ul {
    all: revert;
  }
  p:empty:after {
    content: "\00a0";
  }
  svg {
    display: inline-block;
  }
}

.remove-arrow::-webkit-inner-spin-button,
.remove-arrow::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}
.thin-scroll::-webkit-scrollbar {
  width: 1px;
}
.thin-scroll::-webkit-scrollbar-thumb {
  background-color: #c4c4c4;
  border-radius: 5px;
}
.datepicker-calendar {
  border: none !important;
  --tw-text-opacity: 1 !important;
  color: rgb(75 85 99 / var(--tw-text-opacity)) !important;
  --tw-shadow: 0 25px 50px -12px rgb(0 0 0 / 0.25) !important;
  --tw-shadow-colored: 0 25px 50px -12px var(--tw-shadow-color) !important;
  box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
    var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow) !important;
}

.datepicker-calendar
  .react-datepicker__monthPicker
  .react-datepicker__month-text--today {
  font-weight: 600 !important;
  --tw-text-opacity: 1 !important;
}
.datepicker-calendar .react-datepicker__header {
  border-color: rgb(210, 207, 207);
  color: #374151 !important;
}
.datepicker-calendar .react-datepicker__month-text--keyboard-selected {
  background-color: #4f46e5 !important;
  color: white !important;
}
.datepicker-calendar .react-datepicker__month-text--keyboard-selected:hover {
  background-color: rgb(30 64 175) !important;
}

.datepicker-calendar
  .react-datepicker__monthPicker
  .react-datepicker__month-text {
  padding: 3px 0 !important;
}

.datepicker-wrapper .react-datepicker__close-icon::after {
  background-color: #4f46e5 !important;
}

.datepicker-wrapper .react-datepicker__close-icon:hover::after {
  background-color: rgb(30 64 175) !important;
}

.rc-slider-handle:before {
  content: attr(aria-valuenow); 
  position: absolute;
  font-size: small;
  bottom: 0.75rem;
}

.react-datepicker__year-select {
  border-radius: 20px;
  height: 1.5rem;
  font-size: 0.75rem;
  line-height: 0;
  position: absolute;
  background-color: transparent;
  top: 3px;
  border: none;
}
